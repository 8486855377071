// COLORS
$darker-blue: #454d66;
$darker-green: #309975;
$lighter-green: #58b368;
$darker-beige: #dad873;
$lighter-beige: #efeeb4;
$white-color: #ffffff;
$black-color: #000000;

.custom-hero-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    width: 100%;
    height: 44vh;
    background-color: $darker-blue;
    /* Background color for fallback */
}

.custom-hero-floating-header-link {
    color: $white-color;
    font-size: large;
    font-weight: 444;
    position: absolute;
    right: 2vw;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    top: 1vh;
    z-index: 1;
}

/* Container for image */
.custom-hero-image-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* Image styling */
.custom-hero-image {
    object-fit: cover;
    /* Ensures the image covers the container */
    object-position: center center;
    /* Centers the image */
    width: 100%;
    /* Makes the image take up the full width */
    height: 100%;
    /* Makes the image take up the full height */
    position: absolute;
}