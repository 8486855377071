// COLORS
$darker-blue: #454d66;
$darker-green: #309975;
$lighter-green: #58b368;
$darker-beige: #dad873;
$lighter-beige: #efeeb4;
$white-color: #ffffff;
$black-color: #000000;

.App {
  text-align: center;
}

.banner-sect {
  min-height: 44vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-sect {
  min-height: 44vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4vh;
  padding-bottom: 4vh;
}

.light-theme {
  background-color: $white-color;
  color: $black-color;
}

.light-theme a {
  color: $darker-blue;
}

.dark-theme {
  background-color: $darker-blue;
  color: $white-color;
}

//
// //
// // // // STANDARDS
// // 
//

.page-container {
  padding: 4vh 4vw 4vh 4vw;
}

.page-container h1 {
  text-align: center;
}

.center-text {
  margin: auto;
}


//
// //
// // // // TYPE STYLES
// // 
//

h1 {
  font-size: calc(10px + 2vmin);
}

a {
  font-style: italic;
}

.green-text {
  color: #52B634;
}

.red-text {
  color: #eb4034;
}

//
// //
// // // // HERO
// // 
// 
.hero-image-title {
  color: $white-color;
  font-size: calc(44px + 4vmin);
  position: absolute;
  top: 0vh;
  z-index: 1;
}

.hero-image {
  height: 62vh;
  width: 100vw;
  object-fit: cover;
  object-position: 60% 64%;
}

//
// //
// // // // OFFERING
// // 
//

.offering-container {
  column-gap: 1vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 2vh;
  width: 98vw;

}

.offering-item {
  align-items: center;
  background-color: $lighter-beige;
  border-radius: 11px;
  color: $black-color;
  display: flex;
  height: 33vh;
  justify-content: center;
}

.offering-c2a {
  color: $white-color;
}

@media screen and (max-width: 768px) {
  .offering-container {
    grid-template-columns: 100%;
    row-gap: 1vh;
    width: 96vw;
  }
}

//
// //
// // // // TESTIMONIALS
// // 
//

.testimonials-title {
  font-size: xx-large;
  font-weight: 200;
  margin-bottom: 3vh;
  margin-top: -2vh;
}

.students-div-wrapper {
  display: grid;
  row-gap: 1vh;
  width: 98vw;
  margin-bottom: 2vh;
}

.students-div-row-1 {
  display: grid;
  grid-template-columns: 62% 37%;
  column-gap: 1%;
}

.students-div-row-2 {
  display: grid;
  grid-template-columns: 20% 58% 20%;
  column-gap: 1%;
}

.student-testimonial-box {
  background-color: $lighter-beige;
  border-radius: 11px;
  color: $black-color;
  display: grid;
  padding: 2vw;
}

.student-testimonial-text {
  font-style: italic;
  text-align: left;
}

.student-attribution-text {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
}

@media screen and (max-width: 768px) {
  .students-div-wrapper {
    width: 96vw;
  }

  .students-div-row-1 {
    grid-template-columns: 100%;
    row-gap: 1vh;
  }

  .students-div-row-2 {
    grid-template-columns: 100%;
    row-gap: 1vh;
  }

  .student-testimonial-box {
    padding: 22px;
  }
}