// COLORS
$darker-blue: #454d66;
$darker-green: #309975;
$lighter-green: #58b368;
$darker-beige: #dad873;
$lighter-beige: #efeeb4;
$white-color: #ffffff;
$black-color: #000000;

body {
    background-color: $darker-blue;
    color: $white-color;
    margin: 0;
    min-height: 100vh;
}

.community-class-detail-grid-container {
    margin-top: 2vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.community-class-detail-grid-item {
    padding: 20px;
    text-align: center;
}

/* Media query for screens smaller than 400px */
@media (max-width: 888px) {
    .community-class-detail-grid-container {
        grid-template-columns: 1fr;
    }

    .border-bottom {
        border-bottom: white 2px solid;
    }
}

.community-class-detail-upcoming-classes-container {
    text-align: center;
}

.upcoming-class-dates-container {
    display: inline-block;
}

a {
    color: white;
}