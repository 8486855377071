.wrapper {
    display: grid;
    grid-gap: 2vw;
    grid-template-columns: 42% 48%;
    max-width: 44vw;
}

.title-cell {
    align-items: center;
    display: flex;
    text-align: right;
}

.text-cell {
    text-align: left;
}

@media screen and (max-width: 480px) {
    .wrapper {
        grid-template-columns: 100%;
    }

    .title-cell h1 {
        text-align: center;
    }
}