.generic-hero {
    height: 22vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.generic-hero-image {
    height: 22vh;
    width: 100vw;
    object-fit: cover;
}

.generic-hero-image-title {
    color: white;
    font-size: calc(44px + 4vmin);
    position: absolute;
    top: 0vh;
    z-index: 1;
}

.outlined-text {
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
}